import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const QmsVendorTakeoff = () => {
  return (
    <MicroFrontEnd
      name="QmsVendorTakeoff"
      src={apiConfig.microFrontEnds.QmsVendorTakeoff}
    />
  )
}

export default QmsVendorTakeoff
